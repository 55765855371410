<template>
  <b-modal
    visible
    scrollable
    centered
    size="lg"
    :title="'Полис № ' + currentPolicyData.item.policyId"
    :hide-footer="isLoading"
    body-class="modalContainer"
    @hidden="onClose"
    @ok="onClose"
  >
    <b-overlay
      v-if="isLoadingOverlay"
      :show="isLoadingOverlay"
      variant="transparent"
      rounded="lg"
      style="height: 10rem"
      opacity="0.6"
      @hidden="onHidden"
    />

    <template v-else>
      <div class="modal-container">
        <b-row>
          <b-col>
            <b-form-group
              label="Фамилия"
            >
              <b-form-input
                v-model="$v.policyData.lastName.$model"
                type="text"
                :placeholder="'Введите фамилию'"
                :state="validateState('lastName')"
                trim
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >
                Данное поле обязательно*
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Имя"
            >
              <b-form-input
                v-model="$v.policyData.firstName.$model"
                type="text"
                :placeholder="'Введите имя'"
                :state="validateState('firstName')"
                trim
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >
                Данное поле обязательно*
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Отчество"
            >
              <b-form-input
                v-model="policyData.middleName"
                type="text"
                :placeholder="'Введите отчество'"
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row :class="{'mb-4': $v.birthday.$error}">
          <b-col>
            <b-form-group label="Дата рождения">
              <base-date-picker
                v-model="$v.birthday.$model"
                :error="$v.birthday.$error"
                :errors="errorsValidation.birthday"
                fluid
                class="crm-datepicker"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Номер телефона">
              <b-form-input
                :id="`type-${'text'}`"
                v-model="policyData.phone"
                placeholder="Введите номер телефона"
                :type="'text'"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >
                Данное поле обязательно*
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Пол">
              <b-form-select
                v-model="genderValue"
                :options="optionsGender"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Номер полиса"
            >
              <b-form-input
                v-model="$v.policyData.policyId.$model"
                type="text"
                :placeholder="'Введите номер'"
                :state="validateState('policyId')"
                trim
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >
                Данное поле обязательно*
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row :class="{'mb-4': $v.startInputDate.$error || $v.endInputDate.$error}">
          <b-col>
            <b-form-group label="Дата начала">
              <base-date-picker
                v-model="$v.startInputDate.$model"
                :error="$v.startInputDate.$error"
                :errors="errorsValidation.startInputDate"
                :max-date="maxPolicyEditData"
                fluid
                class="crm-datepicker"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="1"
            class="d-flex align-items-center"
          >
            <b-button
              v-if="!policyData.isDetached && checkFeatureAccess({ name: 'Возможность открепить пациента ПО ДАТЕ', url: '/policy-list' })"
              v-b-tooltip.hover
              title="Открепление полиса по Дате начала = Дате окончания"
              variant="danger"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              pill
              class="mt-3"
              @click="openDetachModal"
            >
              <b-icon icon="x" />
            </b-button>
          </b-col>
          <b-col>
            <b-form-group label="Дата окончания">
              <base-date-picker
                v-model="$v.endInputDate.$model"
                :error="$v.endInputDate.$error"
                :errors="errorsValidation.endInputDate"
                :min-date="minPolicyEditDateTo"
                fluid
                class="crm-datepicker"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Дата загрузки полиса">
              <b-form-input
                :id="`type-${'date'}`"
                v-model="createdAt"
                :type="'date'"
                disabled
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >
                Данное поле обязательно*
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Статус полиса">
              <b-form-select
                v-model="isStatus"
                :options="optionsStatus"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Страховая компания">
              <b-form-select
                v-model="$v.policyData.companyName.$model"
                :options="insuranceCompanies"
                value-field="title"
                text-field="title"
                class="select-specialization"
                placeholder="Выберите страховую компанию"
                label="title"
                :state="validateState('companyName')"
                @input="changeCompany"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >
                Данное поле обязательно*
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Страховая программа">
              <b-form-select
                v-model="$v.policyData.programName.$model"
                :options="insurancePrograms"
                value-field="name"
                text-field="name"
                class="select-specialization"
                placeholder="Выберите страховую программу"
                label="name"
                :state="validateState('programName')"
                @input="changeProgram"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >
                Данное поле обязательно*
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Страховая подпрограмма">
              <b-form-select
                v-model="$v.policyData.subProgramV2Id.$model"
                :options="insuranceSubprograms"
                value-field="id"
                text-field="name"
                placeholder="Выберите страховую подпрограмму"
                label="name"
                trim
                :state="validateState('subProgramV2Id')"
                @input="onSubprogramChange($event)"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >
                Данное поле обязательно*
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="currentPolicyData.item.subprogram.canChangeLimit">
          <b-col>
            <b-form-group label="Лимит">
              <b-form-input
                :id="`type-${'text'}`"
                v-model="policyData.changedLimit"
                placeholder="Введите лимит"
                :type="'text'"
                @keypress="onKeydown"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Страхователь">
              <b-form-input
                :id="`type-${'text'}`"
                v-model="policyData.ensurerName"
                placeholder="Введите страхователя"
                :type="'text'"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Осознанная покупка">
              <b-form-textarea
                v-model="policyData.consciousPurchase"
                placeholder="Введите комментарий"
                rows="3"
                size="sm"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <b-form-checkbox
                v-model="policyData.isTest"
              >
                <p class="mt-2">
                  Тестовый полис
                </p>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="historyPolicyData?.length">
          <b-col>
            <b-form-group>
              <span
                class="mt-2"
                role="button"
                @click="isHistoryVisible = !isHistoryVisible"
              >
                История полиса
                <b-icon
                  v-if="!isHistoryVisible"
                  icon="arrow-down-short"
                  aria-hidden="true"
                />
                <b-icon
                  v-else
                  icon="arrow-up-short"
                  aria-hidden="true"
                />
              </span>
              <div
                v-if="isHistoryVisible"
                class="mt-4"
              >
                <b-table
                  :bordered="true"
                  :hover="true"
                  :fields="fields"
                  small
                  :items="historyPolicyData"
                >
                  <template v-slot:cell(date)="row">
                    <span>{{ parseData(row.item.date) }}</span>
                  </template>
                </b-table>
              </div>
              <div v-else />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="prolongationPolicyData.prolongationDates.length">
          <b-col>
            <b-form-group>
              <span
                class="mt-2"
                role="button"
                @click="isProlongationVisible = !isProlongationVisible"
              >
                Старые полисы
                <b-icon
                  v-if="!isProlongationVisible"
                  icon="arrow-down-short"
                  aria-hidden="true"
                />
                <b-icon
                  v-else
                  icon="arrow-up-short"
                  aria-hidden="true"
                />
              </span>
              <b-collapse
                id="collapse-3"
                v-model="isProlongationVisible"
                class="mt-4"
              >
                <b-table
                  :bordered="true"
                  :hover="true"
                  small
                  :fields="prolongationFields"
                  :items="prolongationPolicyData.prolongationDates"
                >
                  <template v-slot:cell(startDate)="row">
                    <span>{{ parseData(row.item.startDate) }}</span>
                  </template>
                  <template v-slot:cell(endDate)="row">
                    <span>{{ parseData(row.item.endDate) }}</span>
                  </template>
                </b-table>
              </b-collapse>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </template>

    <template #modal-footer>
      <div
        v-if="currentPolicyData"
        class="w-100 d-flex justify-content-between"
      >
        <b-button
          v-if="checkFeatureAccess({ name: 'Возможность открепить от пациента', url: '/policy-list' })"
          variant="info"
          :type="$const.PRIMARY_BUTTON"
          @click="onDetachPolicy"
        >
          Открепить от пациента
        </b-button>

        <b-button
          v-if="policyData.isDetached === false && checkFeatureAccess({ name: 'Возможность открепить пациента ПО ДАТЕ', url: '/policy-list' })"
          variant="info"
          :type="$const.PRIMARY_BUTTON"
          @click="onDetachToEndDate"
        >
          Открепить по дате
        </b-button>
        <div class="d-flex justify-content-end">
          <b-overlay
            v-if="checkFeatureAccess({ name: 'Возможность изменить', url: '/policy-list' })"
            :show="isLoadingButton"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            @hidden="onHidden"
          >
            <b-button
              ref="button"
              class="mr-2"
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              :disabled="isLoadingButton"
              @click="onEditPolicy"
            >
              Сохранить
            </b-button>
          </b-overlay>

          <b-button
            variant="danger"
            :type="$const.PRIMARY_BUTTON"
            :disabled="isLoading"
            @click="onClose"
          >
            Отмена
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  format,
  parseISO,
  // formatISO,
} from '@evd3v/date-fns';
import { BaseDatePicker } from '@/components/base';
import { mixinRoles } from '@/mixins';

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import * as types from '@/store/types';
import { isoWithoutTimeZone } from '@/helpers/utils';
import { showValidationErrorMessage } from '@/helpers/messages';

export default {
  name: 'PolicyViewModal',
  components: {
    BaseDatePicker,
  },
  mixins: [validationMixin, mixinRoles],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    currentPolicyData: {
      type: Object,
      default: null,
    },
    paginationParams: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      isHistoryVisible: false,
      isProlongationVisible: false,
      isLoadingOverlay: true,
      passwordShow: true,
      passwordInputType: 'password',
      startInputDate: null,
      endInputDate: null,
      birthday: null,
      createdAt: null,
      testDate: null,
      isDetached: false,
      fields: [
        {
          key: 'date',
          label: 'Дата',
          sortable: true,
        },
        {
          key: 'action',
          label: 'Изменения',
          sortable: true,
        },
      ],
      prolongationFields: [
        {
          key: 'startDate',
          label: 'Дата начала',
          sortable: true,
        },
        {
          key: 'endDate',
          label: 'Дата окончания',
          sortable: true,
        },
      ],
      historyPolicyData: null,
      prolongationPolicyData: null,
      policyData: {
        policyId: null,
        startDate: null,
        endDate: null,
        isActivated: null,
        isDeleted: null,
        companyName: null,
        changedLimit: null,
        programName: null,
        description: null,
        subprogramId: null,
        subProgramV2Id: null,
        phone: null,
        birthday: null,
        createdAt: null,
        ensurerName: null,
        consciousPurchase: null,
        isTest: false,
      },
      insurancePrograms: [],
      insuranceSubprograms: [],
      subprogramDetailData: null,
      selected: null,
      isStatus: !!this.currentPolicyData.item.personId,
      optionsStatus: [
        { value: false, text: 'Откреплен' },
        { value: true, text: 'Прикреплен' },
      ],
      optionsGender: [
        { value: null, text: 'Выберите пол' },
        { value: 'female', text: 'Женский' },
        { value: 'male', text: 'Мужской' },
      ],
      genderValue: null,
    };
  },
  validations: {
    policyData: {
      policyId: { required },
      firstName: { required },
      lastName: { required },
      companyName: { required },
      programName: { required },
      subProgramV2Id: { required },
    },
    startInputDate: { required },
    endInputDate: { required },
    birthday: { required },
  },
  computed: {
    ...mapGetters({
      isLoadingButton: types.POLICY_IS_LOADING_BUTTON_GET,
    }),
    minPolicyEditDateTo() {
      return this.startInputDate || null;
    },
    maxPolicyEditData() {
      return this.endInputDate || new Date();
    },
    insuranceCompanies() {
      return this.$store.state.Patients.insuranceCompanies
        .filter((i) => {
          if (i.company.isHidden === true) {
            return i.company.id === this.currentPolicyData.item.companyId;
          }

          return true;
        })
        .map(({ company: { id, title } }) => ({ id, title }));
    },
    errorsValidation() {
      const errors = {};

      errors.birthday = [];
      if (!this.$v.birthday.required) {
        errors.birthday.push('Поле не может быть пустым');
      }
      errors.startInputDate = [];
      if (!this.$v.startInputDate.required) {
        errors.startInputDate.push('Поле не может быть пустым');
      }
      errors.endInputDate = [];
      if (!this.$v.endInputDate.required) {
        errors.endInputDate.push('Поле не может быть пустым');
      }
      return errors;
    },
  },
  async created() {
    await this.historyLoad();
    await this.policyProlongationLoad();
    await this.getInsuranceCompanies().then(() => { this.isLoadingOverlay = false; });
    await this.setPolicyEditData();
  },
  methods: {
    onKeydown: ($event) => {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    getFormatDate(date, dateFormat) {
      return date && dateFormat ? format(new Date(date), dateFormat) : null;
    },
    async getInsuranceCompanies() {
      const insuranceCompanies = await this.$store.dispatch(this.$types.COMPANIES_FETCH);
      this.$store.commit(this.$types.INSURANCE_COMPANIES_SET, insuranceCompanies);
    },
    async historyLoad() {
      const policyId = this.currentPolicyData.item.id;

      try {
        const result = await this.$store.dispatch(this.$types.POLICY_HISTORY_FETCH, policyId);
        if (result.length) {
          this.historyPolicyData = result?.sort((a, b) => new Date(b.date) - new Date(a.date));
        }
      } catch (err) {
        console.error(err);
      }
    },
    async policyProlongationLoad() {
      const policyId = this.currentPolicyData.item.id;
      this.prolongationPolicyData = await this.$store.dispatch(this.$types.POLICY_PROLONGATION_FETCH, policyId);
    },
    parseData(ISODate) {
      return format(parseISO(ISODate), 'dd.MM.yyyy');
    },
    onHidden() {
      // Return focus to the button once hidden
      this.$refs.button.focus();
    },
    async onDetachPolicy() {
      const { id } = this.policyData;
      await this.$store.dispatch(this.$types.POLICY_DETACH, {
        id,
      });
      this.$store.commit(this.$types.TOGGLE_POLICY_SEARCH_TRIGGER);
      this.onClose();
    },
    async onDetachToEndDate() {
      // console.log(formatISO(new Date(this.endInputDate).setHours(0, 0, 0, 0)));
      // if (true) return;
      this.isDetached = true;

      const detachDate = this.endInputDate ? isoWithoutTimeZone(new Date(this.endInputDate).setHours(23, 59, 0, 0)) : null;

      await this.$store.dispatch(this.$types.POLICY_DETACH_BY_DATE, {
        policyId: this.policyData.id,
        detachDate,
      });
      this.$store.commit(this.$types.TOGGLE_POLICY_SEARCH_TRIGGER);
      this.onClose();
    },
    async onDetachToDate() {
      this.isDetached = true;

      const detachDate = this.startInputDate ? isoWithoutTimeZone(new Date(this.startInputDate).setHours(0, 0, 0, 0)) : null;

      await this.$store.dispatch(this.$types.POLICY_DETACH_BY_DATE, {
        policyId: this.policyData.id,
        detachDate,
      });
      this.$store.commit(this.$types.TOGGLE_POLICY_SEARCH_TRIGGER);
      this.onClose();
    },
    openDetachModal() {
      this.$store.commit(
        this.$types.OPEN_MODAL, {
          name: 'DetachPolicyModal',
          props: {
            afterFunc: this.onDetachToDate,
          },
        },
      );
    },
    changeCompany() {
      this.policyData.programName = null;
      this.policyData.description = null;
      this.insurancePrograms = [];
      this.insuranceSubprograms = [];
      this.setInsuranceEntities();
    },
    changeProgram() {
      this.policyData.subProgramV2Id = null;
      this.insuranceSubprograms = [];
      this.setInsuranceEntities();
    },
    onSubprogramChange(subprogramId) {
      this.subprogramDetailData = null;
      this.isSubprogramDetailOpen = false;
      if (!subprogramId) return;

      const subprogram = this.insuranceSubprograms.filter((subprog) => subprog.id === subprogramId)[0];
      this.policyData.description = subprogram.name;
      this.policyData.subProgramV2Id = subprogram.id;
    },
    setInsuranceEntities() {
      if (!this.policyData.companyName) return;

      const companyObject = this.$store.state.Patients.insuranceCompanies
        .filter(({ company: { title } }) => title === this.policyData.companyName)[0];

      const programs = companyObject.programsWithSubprograms
        .filter((i) => {
          if (i.program?.isHidden === true) {
            return i.program.id === this.currentPolicyData.item.programId;
          }

          return true;
        })
        .map(({ program: { id, name } }) => ({ id, name }));

      this.insurancePrograms = programs;

      if (!this.policyData.programName) return;

      const programObject = companyObject.programsWithSubprograms.filter(({ program: { name } }) => name === this.policyData.programName)[0];

      this.insuranceSubprograms = programObject.subPrograms
        .filter((i) => {
          if (i.isHidden === true) {
            return i.subprogramId === this.currentPolicyData.item.subProgramV2Id;
          }
          return true;
        })
        .map(({ subprogramId, name }) => ({ id: subprogramId, name }));
    },
    setPolicyEditData() {
      this.policyData = {
        ...this.currentPolicyData.item,
        startDate: this.currentPolicyData.item.startDate ? parseISO(this.currentPolicyData.item.startDate) : null,
        endDate: this.currentPolicyData.item.endDate ? parseISO(this.currentPolicyData.item.endDate) : null,
        birthday: this.currentPolicyData.item.birthday ? parseISO(this.currentPolicyData.item.birthday) : null,
        createdAt: this.currentPolicyData.item.createdAt ? parseISO(this.currentPolicyData.item.createdAt) : null,
      };

      this.startInputDate = this.policyData.startDate;
      this.endInputDate = this.policyData.endDate;
      this.birthday = this.policyData.birthday;
      this.createdAt = this.getFormatDate(this.policyData.createdAt, 'yyyy-MM-dd');

      this.setInsuranceEntities();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.policyData[name];
      return $dirty ? !$error : null;
    },
    passwordDisplay() {
      this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password';
      this.passwordShow = !this.passwordShow;
    },
    async onClose() {
      this.$emit('input', false);
    },
    onCheckValidation() {
      this.$v.policyData.policyId.$touch();
      this.$v.policyData.programName.$touch();
      this.$v.policyData.companyName.$touch();
      this.$v.policyData.subProgramV2Id.$touch();
      this.$v.policyData.firstName.$touch();
      this.$v.policyData.lastName.$touch();
      if (this.$v.policyData.policyId.$anyError
        || this.$v.policyData.programName.$anyError
        || this.$v.policyData.companyName.$anyError
        || this.$v.policyData.subProgramV2Id.$anyError
        || this.$v.policyData.firstName.$anyError
        || this.$v.policyData.lastName.$anyError
      ) {
        return false;
      }
      return true;
    },
    async onEditPolicy() {
      this.$v.$touch();
      if (!this.onCheckValidation()) return;
      if (this.$v.$error) {
        showValidationErrorMessage();
      } else {
        const formattedPolicyData = { ...this.policyData };

        formattedPolicyData.policyId = formattedPolicyData.policyId.trim();
        formattedPolicyData.startDate = this.startInputDate ? isoWithoutTimeZone(this.startInputDate) : null;
        formattedPolicyData.endDate = this.endInputDate ? isoWithoutTimeZone(this.endInputDate) : null;
        formattedPolicyData.birthday = this.birthday ? isoWithoutTimeZone(this.birthday) : null;
        formattedPolicyData.createdAt = this.createdAt ? isoWithoutTimeZone(parseISO(this.createdAt)) : null;
        if (this.isDetached) formattedPolicyData.isDetached = true;

        await this.$store.dispatch(this.$types.PUT_POLICY_EDIT, formattedPolicyData);
        await this.onClose();
        await this.$store.dispatch(this.$types.GET_POLICY_BY_NUMBER, this.paginationParams);
      }
    },
  },
};
</script>

<style lang="scss">
.modalContainer{
  line-height: 0.5 !important;
}
.table {
  line-height: 1.3 !important;
}
</style>
